/***
*
*   SIGN UP STEP 1
*   Signup form for account owners
*   Step 1: create account
*   Step 2: verify email address
*   Step 3: verify business
*
**********/

import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, Animate, Row, Card, SocialSignin, Form, Link, Button, TextInput, Message, EmailInput, PhoneInput, Loader } from 'components/lib';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { LoadScript } from '@react-google-maps/api';
import { MAPS_API_KEY } from 'lib/googlemaps';
import { capitalizeFirstLetter } from 'helpers';
import moment from 'moment-timezone'

const libraries = ['places']

export function Signup() {
  return (
    <LoadScript loadingElement={<Loader />} googleMapsApiKey={MAPS_API_KEY} libraries={libraries}>
      <SignupInner />
    </LoadScript>
  )
}

function SignupInner(props) {
  const context = useContext(AuthContext);

  const [step, setStep] = useState(1)
  const [data, setData] = useState({
    firstname: '',
    lastname: '',
    category: '',
    phonenumber: '',
    business_lookup: ''
  })
  const [notSupported, setNotSupported] = useState(false)
  const [outsideUS, setOutsideUS] = useState(false)
  const [form, setForm] = useState({})
  const [valids, setValids] = useState([])

  const handleInputChange = (name, value, valid) => {
    if (name === 'firstname' || name === 'lastname') value = capitalizeFirstLetter(value)

    setData({ ...data, [name]: value })

    if (!valid) {
      setValids(prev => [...prev, name])
    } else {
      setValids(prev => prev.filter(p => p !== name))
    }
  }

  const nextStepMutation = useMutation({
    mutationFn: () => axios.post('/api/account/verify_supported_category', data)
  })

  const handleNextStep = async () => {
    if (valids.length || Object.values(data).some(d => !d)) return
    try {
      const res = await nextStepMutation.mutateAsync()
      const fdata = res?.data?.data
      if (fdata?.outsideUS) {
        return setOutsideUS(true)
      }
      if (!fdata?.supported) {
        return setNotSupported(true)
      }

      localStorage.setItem('signup', JSON.stringify({
        business_lookup: fdata?.form?.business_lookup,
        firstname: data.firstname,
        lastname: data.lastname,
        phonenumber: data.phonenumber,
        timezone: moment.tz.guess(),
      }))
      setForm(fdata?.form)
      setStep(2)
      return
    } catch (e) {
    }
  }

  useEffect(() => {
    const maps = window?.google?.maps
    if (!maps) return

    const businessLookupInput = document.getElementById('business_lookup');
    const autocomplete = new maps.places.Autocomplete(businessLookupInput);

    const placeChanged = () => {
      const place = autocomplete.getPlace();
      if (!place?.place_id) return;

      const service = new maps.places.PlacesService(businessLookupInput);

      service.getDetails({ placeId: place.place_id, fields: ['type'] }, async (result, status) => {
        if (status !== maps.places.PlacesServiceStatus.OK) return;

        setData(prev => ({ ...prev, category: result.types?.[0], business_lookup: businessLookupInput.value }))
      })
    }

    autocomplete.addListener('place_changed', placeChanged)


    return () => {
      window.google.maps.event.clearInstanceListeners(autocomplete)
    }
  }, [window.google])

  const getValid = (name) => {
    return valids.length && !valids.includes(name)
  }

  return (
    <Animate type='pop' key={step}>
      <Row title={step === 1 ? 'Get Started' : 'Create Your Account'}>
        <Card loading={false} className='max-w-xl' center>

          {
            (notSupported || outsideUS) ?
              <>
                {
                  notSupported && <Message
                    title={`Business not supported`}
                    type='error'
                    text="We regret to inform you that our services currently do not extend to your business category. Rest assured, we will notify you via email as soon as we have tailored our services to cater to your type of business, Should you require additional help or information, please don't hestiate to contact our support team at Support@enzak.com"
                  />
                }
                {
                  outsideUS && <Message
                    title={`Country not supported`}
                    type='error'
                    text="We are currently providing service to US customers only at this time. We will keep your information on file and send you an email when we expand in to your country. Thank you for your consideration."
                  />
                }
              </>
              :
              step === 1 && (
                <div>
                  <div className='sm:grid grid-cols-2 gap-4'>
                    <TextInput
                      label='First Name'
                      name='firstname'
                      className='mt-4'
                      required
                      value={data.firstname}
                      onChange={handleInputChange}
                      valid={getValid('firstname')}
                    />
                    <TextInput
                      label='Last Name'
                      name='lastname'
                      className='mt-4'
                      required
                      value={data.lastname}
                      onChange={handleInputChange}
                      valid={getValid('lastname')}
                    />
                    <EmailInput
                      label='Email'
                      name='email'
                      className='mt-4'
                      required
                      value={data.email}
                      onChange={handleInputChange}
                      valid={getValid('email')}
                    />
                    <PhoneInput
                      label='Personal Mobile Number'
                      name='phonenumber'
                      className='mt-4'
                      required
                      value={data.phonenumber}
                      onChange={handleInputChange}
                      valid={getValid('phonenumber')}
                    />
                    <div className='col-span-2'>
                      <TextInput
                        id='business_lookup'
                        label='Business Lookup'
                        name='business_lookup'
                        placeholder='Lookup your business name or address'
                        className='mt-4'
                        required
                        value={data.business_lookup}
                        onChange={handleInputChange}
                        valid={getValid('business_lookup')}
                      />
                    </div>
                  </div>
                  <Button
                    text='Next Step'
                    color='blue'
                    className='mt-4 flex justify-center w-full'
                    disabled={valids.length || Object.values(data).some(d => !d) || nextStepMutation.isLoading}
                    action={handleNextStep}
                    loading={nextStepMutation.isLoading}
                  />
                </div>
              )
          }

          {
            step === 2 && (
              <>
                <SocialSignin network={['google']} showOr signup />
                <Form
                  inputs={{
                    firstname: {
                      label: 'First Name',
                      type: 'text',
                      required: true,
                      errorMessage: 'Please enter your first name',
                      value: form?.firstname,
                    },
                    lastname: {
                      label: 'Last Name',
                      type: 'text',
                      required: true,
                      errorMessage: 'Please enter your last name',
                      value: form?.lastname,
                    },
                    email: {
                      label: 'Email',
                      type: 'email',
                      required: true,
                      containerClass: 'col-span-2',
                      value: form?.email,
                    },
                    password: {
                      label: 'Password',
                      type: 'password',
                      required: true,
                      complexPassword: true,
                      containerClass: 'col-span-2',
                    },
                    confirm_password: {
                      type: 'hidden',
                      value: null,
                    },
                  }}
                  body={{ ...form }}
                  inputsClassName='grid grid-cols-2 gap-4'
                  url='/api/account'
                  method='POST'
                  buttonText='Create Account'
                  callback={context.signin}
                />
                <Button
                  text='Go Back'
                  variant='text'
                  className='mt-2'
                  action={() => setStep(1)}
                />
              </>
            )
          }

          <div className='mt-4'>
            Already registered? <Link url='/signin' text='Sign In' />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
