import { SubNav } from 'components/lib'
import React from 'react'

function Nav() {
  return (
    <SubNav items={[
        { link: '/users', label: 'Users' },
        { link: '/account/billing', label: 'Account Billing' }
      ]}/>
  )
}

export default Nav