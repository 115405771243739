/***
*
*   APP NAV
*   Primary navigation used inside the main app component
*
*   PROPS
*   type: fixed or popup (style of nav on mobile)
*   items: array of objects containing label, link and icon (optional)
*
**********/

import { useState } from 'react';
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Logo, Button, Icon, useView, Permission } from 'components/lib';
import './app.scss';

export function AppNav(props) {

  // state
  const [open, setOpen] = useState(false); // mobile is open
  const [expanded, setExpanded] = useState(false); // desktop is expanded
  const view = useView()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  return (
    <nav className={`sidebar popup ${open && 'open'} ${expanded && 'expanded'}`}
      onMouseEnter={() => setExpanded(true)}
      onMouseLeave={() => setExpanded(false)}>

      <Button
        icon={open ? 'x' : 'menu'}
        color={'dark'}
        size={20}
        className='btn-togglenav'
        action={e => setOpen(!open)}
      />

      <Logo url='/campaigns' />
      <section className='nav-links'>
        {props.items?.map(item => {

          if (item.link) {
            return (
              <Permission key={item.link} permission={item.permission} scope={item.scope}>
                <NavLink
                  onClick={e => {
                    setOpen(false)
                    const truePath = ['/new_campaign', '/edit_campaign'].some(path => location.pathname.startsWith(path))
                    if (truePath) {
                      const leave = searchParams.get('leave')
                      if (leave === 'true') {
                        return truePath
                          ? window.location.replace(item.link)
                          : navigate(item.link)
                      }

                      e.preventDefault()

                      view.modal.show({
                        title: 'Leave Campaign',
                        onlyActions: true,
                        buttonText: 'Yes',
                        text: `Are you sure you want to navigate away, you will lose your campaign content.`,
                        takeAction: () => navigate(item.link)
                      })
                    }
                  }}
                  key={item.label}
                  to={item.link}
                  className={` ${item.className} ${item.highlighted ? 'highlighted' : ''}`}
                // style={{ width: (100 / props.items.length) + '%' }}
                >

                  {item.icon &&
                    <Icon
                      className='icon'
                      image={item.icon}
                      size={open ? 15 : 18}
                      color={open ? 'dark' : 'light'}
                      pack={item.iconpack}
                    />
                  }
                  {item.label &&
                    <span className='label'>
                      {item.label}
                    </span>
                  }

                </NavLink>
              </Permission>
            );
          }

          return (
            <Permission key={item.label} permission={item.permission} scope={item.scope}>
              <div onClick={item.action}>

                {item.icon &&
                  <Icon
                    className='icon'
                    image={item.icon}
                    size={open ? 15 : 18}
                    color={open ? 'dark' : 'light'}
                  />
                }
                {item.label &&
                  <span className='label'>
                    {item.label}
                  </span>
                }
              </div>
            </Permission>
          )
        })}
      </section>
    </nav>
  );
}
