import React from 'react'
import ReactSelect from 'react-select'
import Style from './select.tailwind.js';
import { Label, Error, ClassHelper, PopOver } from 'components/lib';

export function MultiSelect(props) {
    let options = props.options;
    const error = props.errorMessage || 'Please select an option';

    const wrapperStyle = ClassHelper(Style, {
        className: props.className,
        success: props.valid === true,
        errorWrapper: props.valid === false,
        warningWrapper: props.warning,
    });

    function change(value) {
        let valid = true;

        props.onChange(props.name, value, valid,);
        props.callback && props.callback(value)
    }

    return (
        <div className={Style.input}>
            {
                (props.label || props.popOver) && (
                    <div className='flex items-center gap-2 mb-2'>
                        {
                            props.label ? <Label
                                text={props.label}
                                required={props.required}
                                for={props.name}
                                className='!mb-0'
                            /> : null
                        }

                        {
                            props.popOver && <PopOver text={props.popOver} />
                        }
                    </div>

                )
            }

            <ReactSelect
                defaultValue={props.value}
                options={options}
                isMulti
                name={props.name}
                onChange={change}
            />

            {props.valid === false && <Error message={error} className={Style.message} />}
        </div>
    );
}