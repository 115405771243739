import React, { useEffect, useState } from 'react'
import './index.scss'
import { daysOfWeek, monthsOfYear } from 'helpers/states';
import { v4 as uuid } from 'uuid'

export function PhoneWidget(props) {
    const message = props.message || ''
    const phone = '(888) 444-6695'

    const lockId = 'lock' + uuid()
    const messagesId = 'messages' + uuid()
    useEffect(() => {
        var messageCount = parseInt("1");
        if (messageCount > 1 && messageCount <= 3) {
            document.querySelector('.segmentInfo').className = "segmentInfo warn";
        } else if (messageCount > 3) {
            document.querySelector('.segmentInfo').className = "segmentInfo danger";
        }

        var image = "";
        if (image === '') {
            document.querySelectorAll('.image').forEach(function (el) { el.remove() });
        }

        // Interactions
        var bubbleEl = document.querySelector(`#${lockId} .bubble`);
        var messagesEl = document.querySelector(`#${messagesId}`);

        if (!bubbleEl || !messagesEl) return

        bubbleEl.addEventListener('click', function (e) {
            bubbleEl.classList.add('swipe');
            messagesEl.classList.add('show');
            setTimeout(function () { bubbleEl.classList.remove('swipe'); }, 1000);
        });

        document.querySelector(`#${messagesId} .back-button`).addEventListener('click', function (e) {
            messagesEl.classList.remove('show');
        });

    }, [lockId, messagesId])


    useEffect(() => {
        var months = monthsOfYear
        var days = daysOfWeek
        var timeEl = document.querySelector('.time');
        var dateEl = document.querySelector('.date');
        var receivedEl = document.querySelector('.received');
        var received = new Date().getTime();
        function update() {
            var d = new Date();
            timeEl.textContent = ((d.getHours() % 12) || 12) + ':' + ('0' + d.getMinutes()).substr(-2);
            dateEl.textContent = days[d.getDay()] + ', ' + months[d.getMonth()] + ' ' + d.getDate();
            var mins = Math.floor((d.getTime() - received) / 1000 / 60);
            var hrs = Math.floor(mins / 60)
            receivedEl.textContent = mins == 0 ? 'now' : (hrs === 0 ? mins + 'm ago' : hrs + 'h ago');
        }
        update();

        setInterval(update, 1000);
    }, [])


    const messageWithClickableLink = (text) => {
        const urlRegex = /((https?:\/\/)|(www\.))[^\s<>"\n]+/g;
        return text.replace(urlRegex, (url) => {
          const href = url.startsWith('www.') ? `http://${url}` : url;
          return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        })
    };

    return (
        <div className="phone-widget">
            <div id={lockId} className="lock">
                <div className="phone"></div>
                <div className="clock">
                    <div>
                        <div className="time">

                        </div>
                        <div className="date">

                        </div>
                    </div>
                </div>
                <div className="bubble">
                    <div className="messages-header">
                        <span>MESSAGES</span>
                        <span className="received"></span>
                    </div>
                    <div className="message-row">
                        <div className="message-wrapper">
                            <div className="message-from">
                                {phone}
                            </div>
                            <p className="message-body">
                                {message}
                            </p>
                        </div>
                        <div className="image">
                            <img src="http://http.cat/500" />
                        </div>
                    </div>
                </div>
            </div>
            <div id={messagesId} className="messages show">
                <div className="phone-light"></div>
                <div className="back-button"></div>
                <div className="message-from">
                    {phone}
                </div>
                <div className="message-container">
                    <div className="message-wrapper">
                        <div className="image">
                            <img src="https://http.cat/500" />
                        </div>
                        <div className="msg-bubble">
                            <p className="message" dangerouslySetInnerHTML={{
                                __html: messageWithClickableLink(message)
                            }}>
                            </p>
                        </div>
                        <svg className="tail" width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 0C6 12.5 7 16.5 0.5 20C10 20 16.5 14 16.5 9.5C16.5 5 7 -3.38256e-06 6 0Z" fill="#E0E0E0" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}