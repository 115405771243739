import { Animate, Card, useView } from 'components/lib'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@material-tailwind/react'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect } from 'react'

function Unsubscribe() {
    const { id } = useParams()
    const view = useView()

    const emailQ = useQuery(
        ['email', id],
        () => axios.get(`/api/email/${id}`).then(res => res.data)
    )

    useEffect(() => {
        if (emailQ.isError && emailQ.error) {
            view.handleError(emailQ.error)
        }
    }, [emailQ.isError, emailQ.error, view])


    const emailM = useMutation(
        ['email', 'unsubscribe', id],
        (data) => axios.post(`/api/email/unsubscribe/${id}`, { type: data.type }).then(res => res.data)
    )

    return (
        <Animate type='pop'>
            <Card loading={emailQ.isLoading}>
                <div className='container mx-auto my-6 min-h-[60svh]'>
                    <div className="max-w-[40rem] mx-auto p-10 bg-white text-gray-800 rounded-lg shadow-md">
                        {
                            emailM.data?.message === 'success' ? (
                                <>
                                    <p>You have been successfully unsubscribed.</p>
                                </>
                            )
                                : (
                                    <>
                                        <h1 className="text-2xl text-gray-900 font-semibold">Unsubscribe</h1>
                                        <p className="text-gray-900 text-base mt-6">Are you sure you want to unsubscribe <span className="text-blue-700">{emailQ.data?.email}</span> from these emails?</p>

                                        <Button
                                            loading={emailM.isLoading}
                                            className='mt-6 text-sm normal-case rounded'
                                            color='blue'
                                            onClick={() => emailM.mutate({ type: 'sub' })}
                                        >
                                            Unsubcribe
                                        </Button>
                                    </>
                                )
                        }
                    </div>
                </div>
            </Card>
        </Animate>
    )
}

export default Unsubscribe