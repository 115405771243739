/***
*
*   SIGN UP STEP 3
*   Verify Your Business
*
**********/

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Animate, Row, Card, AuthContext, Button, TextInput, PhoneInput, useAPI, useView, CircleSpinner } from 'components/lib';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import VerificationInput from "react-verification-input";

export default function VerifyBusiness(props) {
    const view = useView()
    const context = useContext(AuthContext);
    const varificationInputRef = useRef(null)

    const { data, loading } = useAPI('/api/account/business', 'POST', JSON.parse(localStorage.getItem('signup')));

    useEffect(() => {
        varificationInputRef.current?.focus()

        if (data?.user?.business_lookup) {
            localStorage.removeItem('signup')
        }
    }, [data])

    const [calling, setCalling] = useState(false)
    const [seconds, setSeconds] = useState(30)

    const [verificationCode, setVerificationCode] = useState('')

    useEffect(() => {
        let timeout = null
        if (calling) {
            timeout = setTimeout(() => {
                setCalling(false)
                setSeconds(30)
            }, 15000)
        }
        return () => timeout && clearTimeout(timeout)

    }, [calling])

    useEffect(() => {
        let interval = null
        if (calling) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1)
            }, 1000)
        }
        return () => interval && clearInterval(interval)
    }, [calling])

    const callMutation = useMutation({
        mutationFn: () => axios.post('/api/account/call_business_number')
    })

    const verificationMutation = useMutation({
        mutationFn: (value) => axios.post('/api/account/verification_code', { verification_code: value })
    })

    const handleCall = async () => {
        if (calling) return

        try {
            const res = await callMutation.mutateAsync()
            setCalling(true)
            varificationInputRef.current?.focus()
            view.notification.show(res.data.message, 'success', true)
        } catch (e) {
            view.handleError(e)
        }
    }

    const handleVerificationCode = async (value) => {
        if (value.length > 6) return
        setVerificationCode(value)
    }


    useEffect(() => {
        if (verificationCode.length === 6) {
            verificationMutation.mutateAsync(verificationCode)
                .then(res => {
                    if (res?.data?.business_verified) {
                        view.notification.show('Your business number has been verified.', 'success')
                        return context.signin(res);
                    }
                    view.notification.show('Invalid Verification Code', 'error')
                })
                .catch(e => view.handleError(e))
        }
    }, [verificationCode])

    return (
        <Animate type='pop'>
            <Row title='Verify Your Business'>
                <Card loading={loading} center className='max-w-xl'>
                    <div>
                        <TextInput
                            label='Business Name'
                            name='business_name'
                            type='text'
                            placeholder='Business Name'
                            value={data?.user?.business_name}
                        />

                        <TextInput
                            label='Business Address'
                            name='business_address'
                            type='text'
                            placeholder='Business Address'
                            value={data?.user?.business_address}
                        />

                        <PhoneInput
                            label='Business Phone Number'
                            name='business_phonenumber'
                            type='text'
                            placeholder='Business Phone Number'
                            value={data?.user?.business_phonenumber}
                        />
                    </div>

                    <p className='my-5'>We will be contacting you through your provided business telephone number in order to verify your business. Please ensure you are able to receive this call on your business line, as it will contain a confirmation code necessary for the verification process.</p>

                    <div className='flex items-center flex-wrap gap-y-1 gap-x-4'>
                        <Button
                            text={calling ? seconds + 's' : 'Call Now'}
                            disabled={callMutation.isLoading || calling}
                            className='w-fit'
                            action={handleCall}
                            loading={callMutation.isLoading}
                            small
                        />
                        <span>{data?.user?.business_phonenumber}</span>
                    </div>

                    <VerificationInput
                        ref={varificationInputRef}
                        length={6}
                        classNames={{
                            container: 'mt-4',
                            character: 'border border-blue-300 rounded-md',
                            characterSelected: 'border border-blue-300',
                            characterInactive: 'bg-blue-50',
                        }}
                        value={verificationCode}
                        onChange={handleVerificationCode}
                    />
                    {
                        verificationMutation.isLoading && (
                            <CircleSpinner width={16} height={16} />
                        )
                    }

                </Card>
                <Button
                    text='Sign Out'
                    variant='text'
                    color='red'
                    action={context.signout}
                    className='mt-5'
                />
            </Row>

        </Animate>
    );
}
